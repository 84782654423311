@use '../../shared/common/common.scss' as common;
@use '../../shared/palette/palette.scss' as p;


.about-wrapper {
    background-color: p.$primary;
}

.mobile-image {
    display: none;
}
.info {
    position: relative;
    z-index: 9999;
    background-color: p.$primary;
    @include common.section();

    h1 {
        color: white;
        font-size: 40px;
        margin-top: 0px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    h2 {
        color: white;
        font-size: 32px;
        margin-top: 0px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    p {
        color: white;
        font-size: 20px;
        margin-top: 20px;
    }
    @media screen and (max-width: 450px) {
    
        h1 {
            font-size: 34px;
        }
        p {
            font-size: 16px;
        }
    }

    .about {
        display: flex;
        flex-direction: row;
        height: min-content;

        .blob {
            /* background-image: url(../../assets/static/blob.png); */
            height: 100px;
        }

        .left {
            width: 55%;
            padding-right: 60px;
        }

        .right {

            display: flex;
            justify-content: end;

            .block {
                position: absolute;
                    z-index: 10;
                    width: 45%;
                    height: 100%;
                    top: 0;
                    right: 0px;
                    background-image: url(../../assets/static/Jillian\ White_00058.jpg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: top;
                    border-left: 2px solid white;
                    /* border-bottom: 2px solid white; */
                    filter: brightness(80%);
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .mobile-image {
        display: block !important;
        height: 400px;
        width: 120%;

        position: relative;
        top: 0;
        left: -19%;
        background-image: url(../../assets/static/Jillian\ White_00058.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        background-position-x: -50%;
    }
    .about {
        flex-direction: column;
        .left {
            width: 100%;
            padding-right: 0px !important;
            p {
                width: 100% !important;
            }
        }
        .right {
            display: none !important;
            .block {
                display: none !important;
            }
        }
    }
    
}

.about-section {
    @include common.section();
    background-color: p.$creme;
    border-top-left-radius: 42px;
    /* border-top-right-radius: 42px; */
}