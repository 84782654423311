@use '../../shared/common/common.scss' as c;
@use '../../shared/palette/palette.scss' as p;

@media screen and (max-width: 420px) {
    .appraisal p {
        font-size: 16px !important;
    }
    .appraisal h2 {
        font-size: 24px !important;
    }   
}

@media screen and (max-width: 550px) {
    .leftright {
        padding: 0px !important;
        background-color: transparent !important;
        p {
            margin-top: 0px !important;
            font-size: 20px !important;
        }
    }
    .mobile-close {
        display: none;
    }

    .resize-button-app {
        scale: .5 !important;
    }
    .right {
        margin-top: 0px !important;
        iframe {
            height: 220px !important; 
        }
    }
    .mobile-contact-up {
        display: block !important;
        margin-top: 20px !important;
    }
    .mobile-contact {
        display: block !important;
        border: 2px solid white;
        border-radius: 4px;
        color: p.$primary;
        background-color: white;
        padding: 12px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 18px;
        cursor: pointer;
        width: 100%;
        text-align: center;
    }
    a {
        text-decoration: none;
    }
}
@media screen and (max-width: 750px) {
    .prem .leftright {
        flex-wrap: wrap !important;
        .left {
            height: 300px;
            width: 100% !important;
            padding-right: 0px !important;
            .poster {background-position-x: 49%;}
        }
    }
}
@media screen and (max-width: 600px) {
    .prem .leftright {
        flex-wrap: wrap !important;
        .left {
            height: 300px !important;
            width: 100% !important;
            padding-right: 0px !important;
            .poster {background-position-x: 49%;}
        }
    }
}
@media screen and (max-width: 1050px) {
    .appraisal .leftright {
        flex-wrap: wrap !important;
        .right {
            width: 100% !important;
            margin-top: 20px;
        }
    }
    .prem .leftright {
        flex-wrap: wrap !important;
        .left {
            height: 500px;
            width: 100% !important;
            padding-right: 0px !important;
            p {
                width: 80% !important;
            }
        }
        .right {
            width: 100% !important;
            margin-top: 20px !important;
        }
    }
    .sub .left {
        width: 100% !important;
        padding-right: 0px !important;
        p {
            width: 100% !important;
        }
    }
}
.mobile-contact-up {
    display: none !important;
}
.appraisal, .prem, .information {

    .mobile-contact {
        display: none;
    }
    @include c.section();
    background-color: p.$primary;

    p {
        color: white;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .sub {
        display: flex;
        /* background-color: p.$accent;
        padding: 32px; */
        padding-top: 20px;
        position: relative;

        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

    }
    .leftright {
        display: flex;
        background-color: p.$accent;
        padding: 32px;
        border-radius: 4px;
        .left {
            flex: 1;
            padding-right: 32px;
            .vertical-spacer {height: 24px;}
            .premiere {
                background-image: url('https://i0.wp.com/urbanbridgez.com/wp-content/uploads/2022/10/OA-LOWBALLED_THUMB-TUNE-IN.jpg?w=1200&ssl=1');
                background-repeat: no-repeat;
                background-size: cover;
                background-position-x: 60%;
                height: 100%;
                width: 100%;
                border-radius: 4px;
            }
        }
        .right {
            width: 50%;
            video, iframe {
                width: 100%;
            }
        }
    }

    video, iframe {
        width: 720px;
        border-radius: 4px;
        border: 4px solid white;
    }
}
.prem {
    /* background-image: url("https://cdn.abcotvs.com/dip/images/12341012_OA-LOWBALLED_THUMB-Clean-V1.jpg") !important;
    backdrop-filter: blur(200px); */
    background-repeat: no-repeat;
    background-position-x: right;
    background-color: #070511;
    background-size: contain;
    position: relative;
    .poster {
        background-image: url("https://i0.wp.com/urbanbridgez.com/wp-content/uploads/2022/10/OA-LOWBALLED_THUMB-TUNE-IN.jpg?fit=1200%2C675&ssl=1");
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: 66%;
        border-radius: 4px;
        border: 4px solid white;
    }
    .lefright {
        background-color: white !important;
    }
    .white {
        background-color: transparent  !important;
        padding: 0px;
        video {
            border: 4px solid #fff;
        }
    }
}
.information {
    @include c.section();
    background: linear-gradient(to right, #070511 30%, #090916 45%, #131222 60%, #130f22 70%, #070f1a 80%, #090913 89%) !important;
    backdrop-filter: blur(200px);
    padding-top: 0px !important ;
    .leftright {
        .left {

        }
    }
}

@media screen and (max-width: 540px) {
    .mobile-contact-up {
        display: block !important;
        margin-top: 20px !important;
    }
}