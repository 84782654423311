h1 {
  font-size: 40px;
  font-weight: 600;
  margin-top: 0px;
  color: #fffcf7;
  margin-bottom: 30px;
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 34px !important;
  }
}
h2 {
  font-weight: 600;
  color: #fffcf7;
  font-size: 30px;
  margin: 0;
}

.vertical-spacer {
  height: 20px;
}

.flexspace {
  flex: 1;
}

.green {
  background-color: #3e4a3c !important;
}

.awards .award {
  display: flex;
  margin-top: 20px;
  background-color: #667068;
  width: -moz-min-content;
  width: min-content;
  height: -moz-min-content;
  height: min-content;
  border-radius: 8px;
}
.awards .award .award-logo {
  border-radius: 8px;
  overflow: hidden;
  height: 70px;
  min-width: 70px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.awards .award .award-logo img {
  border-radius: 8px;
  height: 50px;
  width: 50px;
}
.awards .award .award-text {
  padding: 12px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 460px;
}
.awards .award .award-text p {
  margin: 0px;
  font-size: 18px;
}

@media screen and (max-width: 550px) {
  .award {
    width: 100% !important;
  }
  .award-text {
    padding: 6px !important;
    margin-left: 4px !important;
    padding-right: 10px !important;
  }
  .award-text p {
    font-size: 12px !important;
    width: initial !important;
  }
}/*# sourceMappingURL=awards.css.map */