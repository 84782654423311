h1 {
  font-size: 40px;
  font-weight: 600;
  margin-top: 0px;
  color: #fffcf7;
  margin-bottom: 30px;
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 34px !important;
  }
}
h2 {
  font-weight: 600;
  color: #fffcf7;
  font-size: 30px;
  margin: 0;
}

.vertical-spacer {
  height: 20px;
}

.flexspace {
  flex: 1;
}

.green {
  background-color: #3e4a3c !important;
}

@media screen and (max-width: 420px) {
  .appraisal p {
    font-size: 16px !important;
  }
  .appraisal h2 {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 550px) {
  .leftright {
    padding: 0px !important;
    background-color: transparent !important;
  }
  .leftright p {
    margin-top: 0px !important;
    font-size: 20px !important;
  }
  .mobile-close {
    display: none;
  }
  .resize-button-app {
    scale: 0.5 !important;
  }
  .right {
    margin-top: 0px !important;
  }
  .right iframe {
    height: 220px !important;
  }
  .mobile-contact-up {
    display: block !important;
    margin-top: 20px !important;
  }
  .mobile-contact {
    display: block !important;
    border: 2px solid white;
    border-radius: 4px;
    color: #3e4a3c;
    background-color: white;
    padding: 12px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 18px;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  a {
    text-decoration: none;
  }
}
@media screen and (max-width: 750px) {
  .prem .leftright {
    flex-wrap: wrap !important;
  }
  .prem .leftright .left {
    height: 300px;
    width: 100% !important;
    padding-right: 0px !important;
  }
  .prem .leftright .left .poster {
    background-position-x: 49%;
  }
}
@media screen and (max-width: 600px) {
  .prem .leftright {
    flex-wrap: wrap !important;
  }
  .prem .leftright .left {
    height: 300px !important;
    width: 100% !important;
    padding-right: 0px !important;
  }
  .prem .leftright .left .poster {
    background-position-x: 49%;
  }
}
@media screen and (max-width: 1050px) {
  .appraisal .leftright {
    flex-wrap: wrap !important;
  }
  .appraisal .leftright .right {
    width: 100% !important;
    margin-top: 20px;
  }
  .prem .leftright {
    flex-wrap: wrap !important;
  }
  .prem .leftright .left {
    height: 500px;
    width: 100% !important;
    padding-right: 0px !important;
  }
  .prem .leftright .left p {
    width: 80% !important;
  }
  .prem .leftright .right {
    width: 100% !important;
    margin-top: 20px !important;
  }
  .sub .left {
    width: 100% !important;
    padding-right: 0px !important;
  }
  .sub .left p {
    width: 100% !important;
  }
}
.mobile-contact-up {
  display: none !important;
}

.appraisal, .prem, .information {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  padding-bottom: 55px;
  /* border-radius: $radius; */
  background-color: #3e4a3c;
}
.appraisal .mobile-contact, .prem .mobile-contact, .information .mobile-contact {
  display: none;
}
@media screen and (max-width: 650px) {
  .appraisal, .prem, .information {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 450px) {
  .appraisal, .prem, .information {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.appraisal p, .prem p, .information p {
  color: white;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.appraisal .sub, .prem .sub, .information .sub {
  display: flex;
  /* background-color: p.$accent;
  padding: 32px; */
  padding-top: 20px;
  position: relative;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.appraisal .leftright, .prem .leftright, .information .leftright {
  display: flex;
  background-color: #667068;
  padding: 32px;
  border-radius: 4px;
}
.appraisal .leftright .left, .prem .leftright .left, .information .leftright .left {
  flex: 1;
  padding-right: 32px;
}
.appraisal .leftright .left .vertical-spacer, .prem .leftright .left .vertical-spacer, .information .leftright .left .vertical-spacer {
  height: 24px;
}
.appraisal .leftright .left .premiere, .prem .leftright .left .premiere, .information .leftright .left .premiere {
  background-image: url("https://i0.wp.com/urbanbridgez.com/wp-content/uploads/2022/10/OA-LOWBALLED_THUMB-TUNE-IN.jpg?w=1200&ssl=1");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 60%;
  height: 100%;
  width: 100%;
  border-radius: 4px;
}
.appraisal .leftright .right, .prem .leftright .right, .information .leftright .right {
  width: 50%;
}
.appraisal .leftright .right video, .appraisal .leftright .right iframe, .prem .leftright .right video, .prem .leftright .right iframe, .information .leftright .right video, .information .leftright .right iframe {
  width: 100%;
}
.appraisal video, .appraisal iframe, .prem video, .prem iframe, .information video, .information iframe {
  width: 720px;
  border-radius: 4px;
  border: 4px solid white;
}

.prem {
  /* background-image: url("https://cdn.abcotvs.com/dip/images/12341012_OA-LOWBALLED_THUMB-Clean-V1.jpg") !important;
  backdrop-filter: blur(200px); */
  background-repeat: no-repeat;
  background-position-x: right;
  background-color: #070511;
  background-size: contain;
  position: relative;
}
.prem .poster {
  background-image: url("https://i0.wp.com/urbanbridgez.com/wp-content/uploads/2022/10/OA-LOWBALLED_THUMB-TUNE-IN.jpg?fit=1200%2C675&ssl=1");
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: 66%;
  border-radius: 4px;
  border: 4px solid white;
}
.prem .lefright {
  background-color: white !important;
}
.prem .white {
  background-color: transparent !important;
  padding: 0px;
}
.prem .white video {
  border: 4px solid #fff;
}

.information {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  padding-bottom: 55px;
  /* border-radius: $radius; */
  background: linear-gradient(to right, #070511 30%, #090916 45%, #131222 60%, #130f22 70%, #070f1a 80%, #090913 89%) !important;
  -webkit-backdrop-filter: blur(200px);
          backdrop-filter: blur(200px);
  padding-top: 0px !important;
}
@media screen and (max-width: 650px) {
  .information {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 450px) {
  .information {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 540px) {
  .mobile-contact-up {
    display: block !important;
    margin-top: 20px !important;
  }
}/*# sourceMappingURL=appraisal.css.map */