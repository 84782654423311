h1 {
  font-size: 40px;
  font-weight: 600;
  margin-top: 0px;
  color: #fffcf7;
  margin-bottom: 30px;
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 34px !important;
  }
}
h2 {
  font-weight: 600;
  color: #fffcf7;
  font-size: 30px;
  margin: 0;
}

.vertical-spacer {
  height: 20px;
}

.flexspace {
  flex: 1;
}

.green {
  background-color: #3e4a3c !important;
}

.work {
  scroll-margin-top: 58px;
  /* @include common.section(); */
  /* background-color: p.$primary; */
  position: relative;
}
.work a {
  text-decoration: none;
}
.work h1 {
  color: white;
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 30px !important;
  font-weight: 600;
}
@media screen and (max-width: 450px) {
  .work h1 {
    font-size: 34px;
  }
  .work .title {
    font-size: 18px !important;
  }
  .work .peice {
    width: 100% !important;
  }
}
.work .works {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.work .works .peice {
  border: 2px solid white;
  margin-bottom: 28px;
  margin-right: 28px;
  border-radius: 8px;
  height: 400px;
  background-color: #667068;
  display: flex;
  flex-direction: column;
}
.work .works .peice .pin {
  position: absolute;
  top: 10px;
  right: 40px;
  width: 20px;
  height: 20px;
  background-image: url(https://www.seekpng.com/png/full/25-252939_thumbtack-pin-icon-white-transparent.png);
  background-size: contain;
}
.work .works .peice .data {
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-top: 12px;
  flex: 1;
}
.work .works .peice .data .title {
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 14px;
  max-height: 105px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.work .works .peice .data .description {
  min-height: 20px;
  color: rgba(255, 255, 255, 0.726);
}
.work .works .peice .data .date {
  height: 20px;
  color: white;
}
.work .works .peice a .banner {
  width: 100%;
}
.work .works .peice a .banner img {
  /* border: 2px solid white; */
  border-radius: 8px;
  height: 200px;
  width: 100%;
  aspect-ratio: 1/1;
  -o-object-fit: cover;
     object-fit: cover;
}

.react-multiple-carousel__arrow {
  background: rgba(255, 255, 255, 0.5) !important;
}

.works-flex {
  display: flex;
  flex-direction: row;
}/*# sourceMappingURL=media.css.map */