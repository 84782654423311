@use '../../shared/common/common.scss' as c;
@use '../../shared/palette/palette.scss' as p;

@media screen and (max-width: 540px) {
    .mobile-image-speaking {
        display: block !important;
        height: 400px;
        width: 120%;
        position: relative;
        top: 0;
        left: -19%;
        background-image: url(../../assets/static/speaking-background-darkened.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        background-position-x: 105%;
    }
}

.back {

        position: absolute;
        height: 100%;
        width: 100%;
        background-color: p.$primary;

        @mixin bg() {
            background-size: cover;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            transition: all 1s ease;
        }
        .background {
            background: url(../../assets/static/20221021_145859.jpg) no-repeat center center; //fixed;
            @include bg();
        }
        .background-darkened {
            background: url(../../assets/static/engagements.png) no-repeat center center; //fixed;
            @include bg();
        }

        .twoway {
            display: flex;
            flex-direction: row;
            width: 100%;
    
            overflow-y: hidden;
            flex: 1;
    
            .nav {
                width: 180px;
                position: relative;
                .nav-item {
                    a {
                        color: white;
                        text-decoration: underline;
                        font-size: 1.8em;
                        cursor: pointer;
                    }
                    margin-bottom: 12px;
                }
            }
            .way {
                scroll-behavior: smooth;
                flex: 1;
                overflow-y: scroll;
                .photos {
                    margin-bottom: 10px;
                    height: 560px;
                }
                .news {
                    padding-top: 20px;
                }
                .videos {
                    .video {
                        width: min-content;
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        background-color: p.$accent;
                        border-radius: 4px;
                        border: 2px solid white;
                        max-width: 600px;
                        min-width: 600px;
                        .description {
                            flex: 1;
                            padding: 24px;
                            color: white;
                            font-size: 20px;
                        }
                    }
                }
                .podcasts {
                    .pod {
                        display: flex;
                        flex-direction: row;
                        border: 2px solid white;
                   
                        border-radius: 4px;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        background-color: p.$accent;
                       /*  width: 400px; */
                        max-width: 600px;
                        padding: 24px;
                        .head {
                            /* width: 200px; */
                            min-height: 100px;
                            img {
                                min-width: 100px;
                                height: 100px;
                                border-radius: 4px;
                                background-color: white;
                            }
                        }
                        .vertical-line {
                            width: 2px;
                            background-color: white;
                            margin-left: 24px;
                            /* margin-right: 12px; */
                        }
                        .pod-body {
                            padding-left: 24px;
                            display: flex;
                            flex-direction: column;
                            .title {
                                font-size: 20px;
                                color: white;
                                font-weight: 500;
                                margin-bottom: 6px;
                            }
                            .description {
                                color: white;
                                font-size: 16px;
                            }
                            .outlet {
                                border-radius: 4px;
                                /* background-color: p.$primary; */
                                color: white;
                                font-size: 14px;
                                padding: 8px;
                                width: min-content;
                                white-space: nowrap;
                                background-color: p.$primary;
                                cursor: pointer;
                            }
                            .blue {
                                background-color: #101c94;
                            }
                        }
                    }
                }
                h1 {
                    font-weight: 600;
                    color: p.$light-text;
                    font-size: 36px;    
                    margin: 0;
                }
            }
        }

        .vid {
            width: 500px;
            border-radius: 4px;
            /* border-left: 2px solid white; */
            /* border: 2px solid white; */
        }

        .speech-content, .sched {
            position: relative;
            top: 0;
            @include c.section();
            z-index: 9;
            width: 100%;
            background-color: p.$primary;

            .split {
                display: flex;
                flex-direction: row;
                /* height: 100%; */
                width: 100%;
                .left {
                    flex: 1;
                    .reach {
                        
                        /* padding: 24px;
                        background-color: p.$coffe; */
                        width: 50%;
                        border-radius: 6px;
                        
                        display: flex;
                        flex-direction: column;
                        .row {
                            margin-top: 20px;
                            display: flex;
                            width: 100%;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            .col {
                                display: flex;
                                flex-direction: column;
                                width: 48%;
                            }
                        }
                        label {
                            color: white;
                            font-size: 22px;
                            font-weight: 600;
                        }
                        input {
                            border: none;
                            border-radius: 6px;
                            background-color: p.$accent;
                            padding: 14px;
                            margin-top: 8px;
                            margin-bottom: 14px;
                            color: white;
                            &:focus {
                                outline: 2px solid white;
                            }
                        }
                        textarea {
                            color: white;
                            border: none;
                            border-radius: 8px;
                            height: 100px;
                            background-color: p.$accent;
                            padding: 14px;
                            margin-top: 8px;
                            margin-bottom: 14px;
                            &:focus {
                                outline: 2px solid white;
                            }
                        }
                        button {
                            border-radius: 6px;
                            margin-top: 24px;
                            width: min-content;
                            padding: 8px;
                            background-color: p.$secondary;
                            color: p.$primary;
                            font-size: 20px;
                            font-weight: 600;
                            white-space: nowrap;
                        }
                    }
                    .speech {
                        margin-bottom: 24px;
                        background-color: p.$accent;
                        border-radius: 4px;
                        padding: 24px;
                        width: min-content;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .description {
                            color: white;
                            font-size: 20px;
                            margin-bottom: 12px;
                            font-weight: 600;
                        }
                        .video {
                            width: 640px;
                        }
                    }

                }
                .right {
                    position: absolute;
                    z-index: 10;
                    width: 45%;
                    height: 100%;
                    top: 0;
                    right: 0px;
                    background-image: url(../../assets/static/stand.jpg);
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    border-left: 2px solid white;
                    border-bottom: 2px solid white;
                    filter: brightness(80%);
                }
                .right-two {
                    position: absolute;
                    z-index: 10;
                    width: 45%;
                    height: 100vh;
                    top: 0px;
                    right: 0px;
                    /* background-image: url(../../assets/static/three.jpeg); */
                    background-repeat: no-repeat;
                    background-size: cover;

                    border-left: 2px solid white;
                    border-bottom: 2px solid white;
                    filter: brightness(80%);
                }
            }
        }
        
        .sched {
            @include c.section();
            position: relative !important;
            height: min-content;
            background-color: p.$coffe;
        }

    .dim {
        opacity: 0;
    }
}