$forest-green: #44543c;
$accent-green: #4c6140;
$kale-green: #3e4a3c;
$accent-green: #727A6B;
$null-green: #667068;
$lavander: #566563;
$brown: #907361;
$coffe: #B9A98E;
$creme: #C7BDB1;
$white: #ffffff;
$linen: #fffcf7;

//--------------------//

$primary: $kale-green;
$secondary: $linen;
$accent: $null-green;

$light-text: $linen;
$dark-text: 'black';