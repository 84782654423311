@use '../../shared/common/common.scss' as common;
@use '../../shared/palette/palette.scss' as p;

.awards {
    .award {
        display: flex;
        margin-top: 20px;
        background-color: /* #4c6140 */ p.$accent;
        width: min-content;
        height: min-content;
        border-radius: 8px;
        .award-logo {
            
            border-radius: 8px;
            overflow: hidden;
            height: 70px;
            min-width: 70px;
            background-color: white;
            @include common.center();
            img {
                border-radius: 8px;
                height: 50px;
                width: 50px;
            }
        }
        .award-text {
            padding: 12px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            width: 460px;
            p {
                margin: 0px;
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 550px) { 
    .award {
        width: 100% !important;
    }
    .award-text {
        padding: 6px !important;
        margin-left: 4px !important;
        padding-right: 10px !important;
        p {
            font-size: 12px !important;
            width: initial !important;
        }
    }
    
}