.back-bg {
    /* overflow-x: hidden; */
    position: relative;
    height: 100vh;
    overflow-y: hidden;

    .bg {
        
        position: relative;
        height: 100%;
        width: 100%;

        a {text-decoration: none; color: white;}
        .credits {
            position: absolute;
            color: white;
            opacity: 0.5;
            z-index: 10;
            bottom: 20px;
            right: 20px;
            font-size: 14px;
            text-decoration: underline;
        }

        .brief-right {
            z-index: 99;
            position: absolute; //fixed
            top: 8%;
            width: 500px;
            margin-left: 70px;

            .name {
                position: relative;
                font-size: 162px;
                color: white;
                z-index: 99;
                div {height: 160px;} //name vertical spacing fix
            }

            .punchline {
                margin-left: 20px;
                margin-top: 60px;
                position: relative;
                color: white;
                font-size: 30px;
                .go {
                    z-index: 1;
                    margin-top: 30px;
                    font-size: initial;
                    position: relative;
                    display: flex;
                }
            }
        }

        @mixin bg() {
            background-size: cover;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            z-index: 1;
            transition: all 1s ease;
            overflow-x: hidden;
        }
        .background {
            /* background: url('https://jillianwhite.nyc3.cdn.digitaloceanspaces.com/jwbgl.png') no-repeat center center; */ //fixed;
            background: url(../../assets/static/jwbgl.png) no-repeat center center; //fixed;
            @include bg();
        }
        .background-darkened {
            /* background: url('https://jillianwhite.nyc3.cdn.digitaloceanspaces.com/jwbgh.png') no-repeat center center; */ //fixed;
            background: url(../../assets/static/jwbgh.png) no-repeat center center; //fixed;
            @include bg();
        }
    }

    .dim {
        opacity: 0;
    }
}

//resize text 
@media screen and (max-height: 1370px) {


}
@media screen and (max-width: 450px) {

    
}

@media screen and (max-width: 550px) {
    .bg {
        width: 115% !important;
        height: 160% !important;
        /* background-position-y: bottom !important; */
    }
    .resize-button {
        display: none;
    }
    .resize-button-app {
        display: none;
    }

    .brief-right {
        margin-left: 40px !important;
        top: 5% !important;
        
        .name {
            font-size: 80px !important;
            div {height: 80px !important;} //name vertical spacing fix
        }
        .punchline {
            margin-left: 4px !important;
            margin-top: 30px !important;
            font-size: 20px !important;
            width: 50%;
            .go {
                margin-top: 20px !important;
               
            }
        }
    }
}