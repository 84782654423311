h1 {
  font-size: 40px;
  font-weight: 600;
  margin-top: 0px;
  color: #fffcf7;
  margin-bottom: 30px;
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 34px !important;
  }
}
h2 {
  font-weight: 600;
  color: #fffcf7;
  font-size: 30px;
  margin: 0;
}

.vertical-spacer {
  height: 20px;
}

.flexspace {
  flex: 1;
}

.green {
  background-color: #3e4a3c !important;
}

@media screen and (max-width: 540px) {
  .mobile-image-speaking {
    display: block !important;
    height: 400px;
    width: 120%;
    position: relative;
    top: 0;
    left: -19%;
    background-image: url(../../assets/static/speaking-background-darkened.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    background-position-x: 105%;
  }
}
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #3e4a3c;
}
.back .background {
  background: url(../../assets/static/20221021_145859.jpg) no-repeat center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: all 1s ease;
}
.back .background-darkened {
  background: url(../../assets/static/engagements.png) no-repeat center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: all 1s ease;
}
.back .twoway {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-y: hidden;
  flex: 1;
}
.back .twoway .nav {
  width: 180px;
  position: relative;
}
.back .twoway .nav .nav-item {
  margin-bottom: 12px;
}
.back .twoway .nav .nav-item a {
  color: white;
  text-decoration: underline;
  font-size: 1.8em;
  cursor: pointer;
}
.back .twoway .way {
  scroll-behavior: smooth;
  flex: 1;
  overflow-y: scroll;
}
.back .twoway .way .photos {
  margin-bottom: 10px;
  height: 560px;
}
.back .twoway .way .news {
  padding-top: 20px;
}
.back .twoway .way .videos .video {
  width: -moz-min-content;
  width: min-content;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: #667068;
  border-radius: 4px;
  border: 2px solid white;
  max-width: 600px;
  min-width: 600px;
}
.back .twoway .way .videos .video .description {
  flex: 1;
  padding: 24px;
  color: white;
  font-size: 20px;
}
.back .twoway .way .podcasts .pod {
  display: flex;
  flex-direction: row;
  border: 2px solid white;
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #667068;
  /*  width: 400px; */
  max-width: 600px;
  padding: 24px;
}
.back .twoway .way .podcasts .pod .head {
  /* width: 200px; */
  min-height: 100px;
}
.back .twoway .way .podcasts .pod .head img {
  min-width: 100px;
  height: 100px;
  border-radius: 4px;
  background-color: white;
}
.back .twoway .way .podcasts .pod .vertical-line {
  width: 2px;
  background-color: white;
  margin-left: 24px;
  /* margin-right: 12px; */
}
.back .twoway .way .podcasts .pod .pod-body {
  padding-left: 24px;
  display: flex;
  flex-direction: column;
}
.back .twoway .way .podcasts .pod .pod-body .title {
  font-size: 20px;
  color: white;
  font-weight: 500;
  margin-bottom: 6px;
}
.back .twoway .way .podcasts .pod .pod-body .description {
  color: white;
  font-size: 16px;
}
.back .twoway .way .podcasts .pod .pod-body .outlet {
  border-radius: 4px;
  /* background-color: p.$primary; */
  color: white;
  font-size: 14px;
  padding: 8px;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
  background-color: #3e4a3c;
  cursor: pointer;
}
.back .twoway .way .podcasts .pod .pod-body .blue {
  background-color: #101c94;
}
.back .twoway .way h1 {
  font-weight: 600;
  color: #fffcf7;
  font-size: 36px;
  margin: 0;
}
.back .vid {
  width: 500px;
  border-radius: 4px;
  /* border-left: 2px solid white; */
  /* border: 2px solid white; */
}
.back .speech-content, .back .sched {
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  padding-bottom: 55px;
  /* border-radius: $radius; */
  z-index: 9;
  width: 100%;
  background-color: #3e4a3c;
}
@media screen and (max-width: 650px) {
  .back .speech-content, .back .sched {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 450px) {
  .back .speech-content, .back .sched {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.back .speech-content .split, .back .sched .split {
  display: flex;
  flex-direction: row;
  /* height: 100%; */
  width: 100%;
}
.back .speech-content .split .left, .back .sched .split .left {
  flex: 1;
}
.back .speech-content .split .left .reach, .back .sched .split .left .reach {
  /* padding: 24px;
  background-color: p.$coffe; */
  width: 50%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.back .speech-content .split .left .reach .row, .back .sched .split .left .reach .row {
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.back .speech-content .split .left .reach .row .col, .back .sched .split .left .reach .row .col {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.back .speech-content .split .left .reach label, .back .sched .split .left .reach label {
  color: white;
  font-size: 22px;
  font-weight: 600;
}
.back .speech-content .split .left .reach input, .back .sched .split .left .reach input {
  border: none;
  border-radius: 6px;
  background-color: #667068;
  padding: 14px;
  margin-top: 8px;
  margin-bottom: 14px;
  color: white;
}
.back .speech-content .split .left .reach input:focus, .back .sched .split .left .reach input:focus {
  outline: 2px solid white;
}
.back .speech-content .split .left .reach textarea, .back .sched .split .left .reach textarea {
  color: white;
  border: none;
  border-radius: 8px;
  height: 100px;
  background-color: #667068;
  padding: 14px;
  margin-top: 8px;
  margin-bottom: 14px;
}
.back .speech-content .split .left .reach textarea:focus, .back .sched .split .left .reach textarea:focus {
  outline: 2px solid white;
}
.back .speech-content .split .left .reach button, .back .sched .split .left .reach button {
  border-radius: 6px;
  margin-top: 24px;
  width: -moz-min-content;
  width: min-content;
  padding: 8px;
  background-color: #fffcf7;
  color: #3e4a3c;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}
.back .speech-content .split .left .speech, .back .sched .split .left .speech {
  margin-bottom: 24px;
  background-color: #667068;
  border-radius: 4px;
  padding: 24px;
  width: -moz-min-content;
  width: min-content;
}
.back .speech-content .split .left .speech:last-child, .back .sched .split .left .speech:last-child {
  margin-bottom: 0;
}
.back .speech-content .split .left .speech .description, .back .sched .split .left .speech .description {
  color: white;
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
}
.back .speech-content .split .left .speech .video, .back .sched .split .left .speech .video {
  width: 640px;
}
.back .speech-content .split .right, .back .sched .split .right {
  position: absolute;
  z-index: 10;
  width: 45%;
  height: 100%;
  top: 0;
  right: 0px;
  background-image: url(../../assets/static/stand.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  filter: brightness(80%);
}
.back .speech-content .split .right-two, .back .sched .split .right-two {
  position: absolute;
  z-index: 10;
  width: 45%;
  height: 100vh;
  top: 0px;
  right: 0px;
  /* background-image: url(../../assets/static/three.jpeg); */
  background-repeat: no-repeat;
  background-size: cover;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  filter: brightness(80%);
}
.back .sched {
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  padding-bottom: 55px;
  /* border-radius: $radius; */
  position: relative !important;
  height: -moz-min-content;
  height: min-content;
  background-color: #B9A98E;
}
@media screen and (max-width: 650px) {
  .back .sched {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 450px) {
  .back .sched {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.back .dim {
  opacity: 0;
}/*# sourceMappingURL=engagements.css.map */