@use '../../shared/common/common.scss' as c;
@use '../../shared/palette/palette.scss' as p;

.contact {
    position: relative;
    @include c.section();

    background-color: p.$primary;
    min-height: 100vh;
    p {
        color: white;
        font-size: 20px;
        margin-top: 0px;
        width: 50%;
        margin-bottom: 0px;
    }
    a {
        color: p.$coffe;
        text-decoration: underline;
    }
    @media screen and (max-width: 450px) {
        min-height: initial !important;
        h1 {
            font-size: 34px;
        }
        label, button  {
            font-size: 18px !important;
        }
        .right {
            display: none !important;  
        }
        .left {
            width: 100% !important;
        }
        p {
            width: 100% !important;
            font-size: 16px !important;
        }
    }
    
    .contact-form {
        width: 100%;
        display: flex;
        flex: 1;
        .left {
            
            width: 50%;
            display: flex;
            flex-direction: column;


            .row {
                margin-top: 20px;
                display: flex;
                width: 100%;
                flex-wrap: nowrap;
                justify-content: space-between;
                .col {
                    display: flex;
                    flex-direction: column;
                    width: 48%;
                }
            }

            label {
                color: white;
                font-size: 22px;
                font-weight: 600;
            }
            input {
                border: none;
                border-radius: 4px;
                background-color: p.$accent;
                padding: 14px;
                margin-top: 8px;
                margin-bottom: 14px;
                color: white;
                &:focus {
                    outline: 2px solid white;
                }
            }
            textarea {
                height: 30%;
                color: white;
                border: none;
                border-radius: 4px;
                background-color: p.$accent;
                padding: 14px;
                margin-top: 8px;
                margin-bottom: 14px;
                &:focus {
                    outline: 2px solid white;
                }
            }
            button {
                margin-top: 12px;
                width: min-content;
                padding: 8px;
                background-color: p.$secondary;
                color: p.$primary;
                font-size: 20px;
                font-weight: 600;
                white-space: nowrap;
                transition: .2s;
                &:hover {
                    color: p.$coffe;

                }
            }
        

        }
        .right {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            .blob {
                background-image: url('https://static01.nyt.com/images/2023/01/05/multimedia/00blackappraisers-1-7000/00blackappraisers-1-7000-superJumbo.jpg?quality=75&auto=webp');
                
                background-repeat: no-repeat;
                background-size: cover;
                border-left: 2px solid #fffcf7;
                border-radius: 4px;
                height: 100vh;
                position: absolute;
                right: 0;
                top: 0;
                width: 45%;
                background-position-x: 76%;
            }
        }
        
    }
}

@media screen and (max-height: 1370px) {
    .contact {
        min-height: 800px !important;
        max-height: 800px;
        height: 800px;
    }
    .blob {
        min-height: 800px;
        max-height: 800px;
        height: 800px;
    }
}
@media screen and (max-height: 1000px) {
    .contact {
        min-height: 700px !important;
        max-height: 700px;
        height: 700px;
    }
    .blob {
        min-height: 700px;
        max-height: 700px;
        height: 700px;
    }
}
@media screen and (max-width: 1050) {
    .blob {
        background-position-x: 50% !important;
    }
}
@media screen and (max-width: 860px) {
    .left {
        width: 60% !important;
    }
    .right {
        width: 40% !important;
    }
    .blob {
        width: 35% !important;
        background-position-x: 70% !important;
    }
}
@media screen and (max-width: 750px) {
    .contact p {
        width: 100% !important;
    }
    .left {
        width: 100% !important;
    }
    .right {
        width: 0% !important;
    }
    .blob {
        display: none !important;
    }
}