@use '../../shared/common/common.scss' as common;
@use '../../shared/palette/palette.scss' as p;


.content {
    position: relative;

    z-index: 9999;
    /* top: 100vh; */
    overflow-x: hidden;
    width: 100%;

}

.section {
    @include common.section();
    background-color: p.$creme;
}