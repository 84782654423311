h1 {
  font-size: 40px;
  font-weight: 600;
  margin-top: 0px;
  color: #fffcf7;
  margin-bottom: 30px;
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 34px !important;
  }
}
h2 {
  font-weight: 600;
  color: #fffcf7;
  font-size: 30px;
  margin: 0;
}

.vertical-spacer {
  height: 20px;
}

.flexspace {
  flex: 1;
}

.green {
  background-color: #3e4a3c !important;
}

.contact {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 55px;
  padding-bottom: 55px;
  /* border-radius: $radius; */
  background-color: #3e4a3c;
  min-height: 100vh;
}
@media screen and (max-width: 650px) {
  .contact {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 450px) {
  .contact {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.contact p {
  color: white;
  font-size: 20px;
  margin-top: 0px;
  width: 50%;
  margin-bottom: 0px;
}
.contact a {
  color: #B9A98E;
  text-decoration: underline;
}
@media screen and (max-width: 450px) {
  .contact {
    min-height: initial !important;
  }
  .contact h1 {
    font-size: 34px;
  }
  .contact label, .contact button {
    font-size: 18px !important;
  }
  .contact .right {
    display: none !important;
  }
  .contact .left {
    width: 100% !important;
  }
  .contact p {
    width: 100% !important;
    font-size: 16px !important;
  }
}
.contact .contact-form {
  width: 100%;
  display: flex;
  flex: 1;
}
.contact .contact-form .left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.contact .contact-form .left .row {
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.contact .contact-form .left .row .col {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.contact .contact-form .left label {
  color: white;
  font-size: 22px;
  font-weight: 600;
}
.contact .contact-form .left input {
  border: none;
  border-radius: 4px;
  background-color: #667068;
  padding: 14px;
  margin-top: 8px;
  margin-bottom: 14px;
  color: white;
}
.contact .contact-form .left input:focus {
  outline: 2px solid white;
}
.contact .contact-form .left textarea {
  height: 30%;
  color: white;
  border: none;
  border-radius: 4px;
  background-color: #667068;
  padding: 14px;
  margin-top: 8px;
  margin-bottom: 14px;
}
.contact .contact-form .left textarea:focus {
  outline: 2px solid white;
}
.contact .contact-form .left button {
  margin-top: 12px;
  width: -moz-min-content;
  width: min-content;
  padding: 8px;
  background-color: #fffcf7;
  color: #3e4a3c;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
  transition: 0.2s;
}
.contact .contact-form .left button:hover {
  color: #B9A98E;
}
.contact .contact-form .right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.contact .contact-form .right .blob {
  background-image: url("https://static01.nyt.com/images/2023/01/05/multimedia/00blackappraisers-1-7000/00blackappraisers-1-7000-superJumbo.jpg?quality=75&auto=webp");
  background-repeat: no-repeat;
  background-size: cover;
  border-left: 2px solid #fffcf7;
  border-radius: 4px;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  width: 45%;
  background-position-x: 76%;
}

@media screen and (max-height: 1370px) {
  .contact {
    min-height: 800px !important;
    max-height: 800px;
    height: 800px;
  }
  .blob {
    min-height: 800px;
    max-height: 800px;
    height: 800px;
  }
}
@media screen and (max-height: 1000px) {
  .contact {
    min-height: 700px !important;
    max-height: 700px;
    height: 700px;
  }
  .blob {
    min-height: 700px;
    max-height: 700px;
    height: 700px;
  }
}
@media screen and (max-width: 1050) {
  .blob {
    background-position-x: 50% !important;
  }
}
@media screen and (max-width: 860px) {
  .left {
    width: 60% !important;
  }
  .right {
    width: 40% !important;
  }
  .blob {
    width: 35% !important;
    background-position-x: 70% !important;
  }
}
@media screen and (max-width: 750px) {
  .contact p {
    width: 100% !important;
  }
  .left {
    width: 100% !important;
  }
  .right {
    width: 0% !important;
  }
  .blob {
    display: none !important;
  }
}/*# sourceMappingURL=contact.css.map */