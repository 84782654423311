.back-bg {
  /* overflow-x: hidden; */
  position: relative;
  height: 100vh;
  overflow-y: hidden;
}
.back-bg .bg {
  position: relative;
  height: 100%;
  width: 100%;
}
.back-bg .bg a {
  text-decoration: none;
  color: white;
}
.back-bg .bg .credits {
  position: absolute;
  color: white;
  opacity: 0.5;
  z-index: 10;
  bottom: 20px;
  right: 20px;
  font-size: 14px;
  text-decoration: underline;
}
.back-bg .bg .brief-right {
  z-index: 99;
  position: absolute;
  top: 8%;
  width: 500px;
  margin-left: 70px;
}
.back-bg .bg .brief-right .name {
  position: relative;
  font-size: 162px;
  color: white;
  z-index: 99;
}
.back-bg .bg .brief-right .name div {
  height: 160px;
}
.back-bg .bg .brief-right .punchline {
  margin-left: 20px;
  margin-top: 60px;
  position: relative;
  color: white;
  font-size: 30px;
}
.back-bg .bg .brief-right .punchline .go {
  z-index: 1;
  margin-top: 30px;
  font-size: initial;
  position: relative;
  display: flex;
}
.back-bg .bg .background {
  /* background: url('https://jillianwhite.nyc3.cdn.digitaloceanspaces.com/jwbgl.png') no-repeat center center; */
  background: url(../../assets/static/jwbgl.png) no-repeat center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: all 1s ease;
  overflow-x: hidden;
}
.back-bg .bg .background-darkened {
  /* background: url('https://jillianwhite.nyc3.cdn.digitaloceanspaces.com/jwbgh.png') no-repeat center center; */
  background: url(../../assets/static/jwbgh.png) no-repeat center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: all 1s ease;
  overflow-x: hidden;
}
.back-bg .dim {
  opacity: 0;
}

@media screen and (max-width: 550px) {
  .bg {
    width: 115% !important;
    height: 160% !important;
    /* background-position-y: bottom !important; */
  }
  .resize-button {
    display: none;
  }
  .resize-button-app {
    display: none;
  }
  .brief-right {
    margin-left: 40px !important;
    top: 5% !important;
  }
  .brief-right .name {
    font-size: 80px !important;
  }
  .brief-right .name div {
    height: 80px !important;
  }
  .brief-right .punchline {
    margin-left: 4px !important;
    margin-top: 30px !important;
    font-size: 20px !important;
    width: 50%;
  }
  .brief-right .punchline .go {
    margin-top: 20px !important;
  }
}/*# sourceMappingURL=background.css.map */