@use '../../shared/common/common.scss' as c;
@use '../../shared/palette/palette.scss' as p;


.color {
	/* background-color: black;
  transition: .4s; */
}

.navigation {
	.mobile-lines {
		display: none;
	}
	.mobile-navigation {
		display: none;
	}
	position: fixed;
	width: 100%;
	padding: 20px 70px 0px 70px;
	z-index: 99999999;
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	height: 50px;

	a {
		text-decoration: none;
	}

	.logo {
		/* width: 100px; */
		white-space: nowrap;
		width: min-content;
		display: flex;
		flex-direction: row;

		.rect {
			white-space: nowrap;
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 1.4em;
			letter-spacing: .1em;
			cursor: pointer;

			.box {
				background-color: p.$accent;
				padding: 4px;
				padding-left: 12px;
				padding-right: 12px;
				border-radius: 2px;
				margin-left: 4px;
				text-align: center;
			}
		}
	}

	display: flex;

	.ico {
		position: relative;
		top: -13px;
		border-radius: 4px;

	}

	.underline {
		ul {
			font-size: 18px;
			margin: 0px auto 0;
			padding: 0;
			list-style: none;
			display: table;
			width: 350px;
			text-align: center;
		}

		li {
			display: table-cell;
			position: relative;
			padding: 0px 0;
		}

		a {
			color: #fff;

			text-decoration: none;

			display: inline-block;
			padding: 0px 10px 10px 10px;
			position: relative;
		}

		a:after {
			background: none repeat scroll 0 0 transparent;
			bottom: 0;
			content: "";
			display: block;
			height: 2px;
			left: 50%;
			position: absolute;
			background: #fff;
			transition: width 0.3s ease 0s, left 0.3s ease 0s;
			width: 0;
		}

		a:hover:after {
			width: 100%;
			left: 0;
		}
	}

	.divider {
		height: 24px;
		width: 2px;
		margin-left: 8px;
		margin-right: 8px;
		background-color: white;
	}
}
.navflexspace {
	flex: 1;
}


.my-social-icon .social-svg {
	color: #BBC7CC;
	background: transparent !important;
}

.my-social-icon .social-svg:hover {
	color: #55acee !important;
	background: white;
	transition: 1s;
}


@media screen and (max-width: 821px) {
	ul {
		font-size: 16px !important;
	}
	.navigation {
		padding: 20px 50px 0px 50px !important;
		justify-content: center !important;
	}
	.ico {
		height: 40px !important;
		width: 40px !important;
		top: -10px !important;
	}
}
@media screen and (max-width: 770px) {
	.navflexspace {
		flex: none !important;
	}
}
@media screen and (max-width: 740px) {
	ul {
		font-size: 14px !important;
	}
}
@media screen and (max-width: 670px) {
	.navigation {
		-webkit-backdrop-filter: none !important;
		backdrop-filter: none !important;  
	}
	ul {
		font-size: 12px !important;
	}
	.ico {
		height: 30px !important;
		width: 30px !important;
		top: -5px !important;
	}
	.mobile-lines {
		box-sizing: content-box;
		/* padding: 4px; */
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
	}
}
@media screen and (max-width: 575px) {
	.navigation {
		-webkit-backdrop-filter: none !important;
		backdrop-filter: none !important;  
	}
	.underline, .divider, .social-svg {
		display: none !important;
	}
	.mobile-lines {
		display: flex !important;
		position: absolute;
		top: 20px;
		right: 30px;
		width: 40px;
		height: 30px;
		z-index: 99;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		.line {
			width: 100%;
			height: 10%;
			background-color: white;
		}
	}
	.mobile-navigation {
		.exit {
			position: absolute;
			top: 30px;
			right: 30px;
			width: 50px;
			height: 50px;
			color: white;
			font-weight: bolder;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 32px !important;
		}
		/* display: flex !important; */
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background-color: p.$primary;
		flex-direction: column;
		padding-top: 150px;
		align-items: center;
		color: white;
		a {
			color: white;
			font-size: 1.8em;
			margin-bottom: 12px;
		}
	}
}

.pop {
	display: flex !important;
}
.gone {
	display: none !important;
}